import ImageOne from "../../images/image1.jpg";
import ImageTwo from "../../images/image2.jpg";
import ImageThree from "../../images/image3.jpg";
import ImageFour from "../../images/image4.jpg";
import ImageFive from "../../images/image5.jpg";
import ImageSix from "../../images/image6.jpg";
import ImageSeven from "../../images/image7.jpg";
import ImageEight from "../../images/image8.jpg";
import ImageNine from "../../images/image9.jpg";
import ImageTen from "../../images/image10.jpg";

export const filterOptions = [
  {
    label: "All",
    id: 1,
  },
  {
    label: "Development",
    id: 2,
  },
  {
    label: "Design",
    id: 3,
  },
];

export const portfolioData = [
  {
    sectionId: 2,
    projectName: "Up and Running with Remix",
    projectLink: "https://egghead.io/courses/up-and-running-with-remix-b82b6bb6",
    image: ImageOne,
  },
  {
    sectionId: 3,
    projectName: "Learn Remix by Building a Social Media Platform with TypeScript and Prisma",
    projectLink: "https://egghead.io/courses/learn-remix-by-building-a-social-media-platform-with-typescript-and-prisma-cddb0550",
    image: ImageTwo,
  },
  {
    sectionId: 2,
    projectName: "ECommerce Product Management & Storefront with GraphCMS, Snipcart, & Next.js",
    projectLink: "https://egghead.io/playlists/ecommerce-product-management-storefront-with-graphcms-snipcart-next-js-13cc0534",
    image: ImageThree,
  },
  {
    sectionId: 3,
    projectName: "Effectively Build RESTful APIs using Next.js API Routes",
    projectLink: "https://egghead.io/courses/effectively-build-restful-apis-using-next-js-api-routes-41c2b1ea",
    image: ImageFour,
  },
  {
    sectionId: 2,
    projectName: "Build a Practical NFT Ticketing Service with Solidity and React",
    projectLink: "https://egghead.io/courses/build-an-nft-based-ticketing-system-1a2f387c",
    image: ImageFive,
  },
  {
    sectionId: 3,
    projectName: "Animate React Apps with Framer Motion",
    projectLink: "https://egghead.io/courses/animate-react-apps-with-framer-motion-aa83f52c",
    image: ImageSix,
  },
  {
    sectionId: 2,
    projectName: "Build a News App with React Native, GraphQL and TypeScript",
    projectLink: "https://egghead.io/courses/build-a-news-app-with-react-native-graphql-and-typescript-08814691",
    image: ImageSeven,
  },
  {
    sectionId: 3,
    projectName: "Build a React App with Authorization and Authentication with AWS Amplify",
    projectLink: "https://egghead.io/playlists/build-a-react-app-with-authorization-and-authentication-8bbacfe9",
    image: ImageEight,
  },
  {
    sectionId: 2,
    projectName: "Migrate a Client-Side Application to React 18 Beta",
    projectLink: "https://egghead.io/playlists/migrate-a-client-side-application-to-react-18-beta-9379f0d1",
    image: ImageNine,
  },
  {
    sectionId: 3,
    projectName: "Manage State in React Apps with Apollo Client and GraphQL",
    projectLink: "https://egghead.io/playlists/synchronize-client-and-server-state-in-react-using-apollo-client-a45b3b89",
    image: ImageTen,
  },
];
