import React from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import { DiApple, DiAndroid } from "react-icons/di";
import { FaDev, FaDatabase } from "react-icons/fa";
import { Animate } from "react-simple-animate";
import PageHeaderContent from "../../components/pageHeaderContent";
import { personalData } from "./utils";
import "./styles.scss";

const About = () => {
  return (
    <section className="about" id="about">
      <PageHeaderContent headerText="About Me" icon={<BsInfoCircleFill size={40} />} />
      <div className="about__content">
        <div className="about__content__personalWrapper">
          <Animate play duration={1} delay={0} start={{ transform: "translateX(-800px)" }} end={{ transform: "translateX(0px)" }}>
            <h3 className="developerContent">Web Development Manager / Sr. Web Developer</h3>
            <p>
              Hi, I am Vivekanand Rao (but my family, friends &amp; colleagues call me Vivek). After getting a Master’s degree in Computer Science from San Francisco State University (SFSU), I am currently working for Egnyte Inc. as a Web Development Manager. Prior to pursuing a Master’s Degree in Computer Science, I worked as a Developer & QA Engineer with Aptech Software Ltd. and Lionbridge India.
              While studying at SFSU, I worked as a Technology Coordinator with California Campus Compact; Technical/Administrative Support Assistant with SFSU’s Information Systems Projects and as a Web Accessibility Evaluator with ACCESS SFSU’s Accessible Technology Initiative. I also interned as a Web Developer with California Department of Financial Institutions. I like learning new tools and
              web technologies and for that I take courses offered by Treehouse, Code School, Envato Tuts+, Learnable , PluralSight and Lynda.com.
            </p>
          </Animate>
          <Animate play duration={1} delay={0} start={{ transform: "translateX(500px)" }} end={{ transform: "translateX(0px)" }}>
            <h3 className="personalContent">Personal Information</h3>
            <ul>
              {personalData.map((item, key) => (
                <li key={key}>
                  <span className="title">{item.label}</span>
                  <span className="value">{item.value}</span>
                </li>
              ))}
            </ul>
          </Animate>
        </div>
        <div className="about__content__servicesWrapper">
          <Animate play duration={1} delay={0} start={{ transform: "translateX(500px)" }} end={{ transform: "translateX(0px)" }}>
            <div className="about__content__servicesWrapper__innerContent">
              <div>
                <DiApple color="var(--selected-theme-main-color)" size={60} />
              </div>
              <div>
                <FaDatabase color="var(--selected-theme-main-color)" size={60} />
              </div>
              <div>
                <DiAndroid color="var(--selected-theme-main-color)" size={60} />
              </div>
              <div>
                <FaDev color="var(--selected-theme-main-color)" size={60} />
              </div>
            </div>
          </Animate>
        </div>
      </div>
    </section>
  );
};

export default About;
