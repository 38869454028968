export const personalData = [
  {
    label: "Name",
    value: "Vivekanand Rao",
  },
  {
    label: "Title",
    value: "Web Development Manager",
  },
  {
    label: "Address",
    value: "Mountain View, California, USA",
  },
  {
    label: "Email",
    value: "raosvivek@yahoo.com",
  },
];
