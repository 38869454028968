import React from "react";

import PageHeaderContent from "../../components/pageHeaderContent";
import { AiFillProject } from "react-icons/ai";
import { filterOptions, portfolioData } from "./utils";
import "./styles.scss";
import { useState } from "react";

const Portfolio = () => {
  const [filterValue, setFilterValue] = useState(1);
  const [hoveredIndex,setHoveredIndex] = useState(null);

  const handleFilter = (id) => {
    setFilterValue(id);
  };
  console.log(filterValue);
  const filteredPortfolioData = filterValue === 1 ? portfolioData : portfolioData.filter((item) => item.sectionId === filterValue);
  return (
    <section id="portfolio" className="portfolio">
      <PageHeaderContent headerText="My Portfolio" icon={<AiFillProject size={40} />} />
      <div className="portfolio__content">
        <ul className="portfolio__content__filter">
          {filterOptions.map((option) => (
            <li onClick={() => handleFilter(option.id)} key={`filter${option.id}`} className={option.id === filterValue ? 'active':''}>
              {option.label}
            </li>
          ))}
        </ul>
        <div className="portfolio__content__cards">
          {filteredPortfolioData.map((item, key) => (
            <div className="portfolio__content__cards__item" key={key} onMouseEnter={()=>setHoveredIndex(key)} onMouseLeave={()=>setHoveredIndex(null)}>
              <div className="portfolio__content__cards__item__img-wrapper">
                <a>
                  <img src={item.image} alt="Project Image" />
                </a>
              </div>
              <div className="overlay">
                {
                  hoveredIndex === key && (
                    <div>
                      <p>{item.projectName}</p>
                      <button>Visit</button>
                    </div>
                  )
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
