export const data = {
  experience: [
    {
      title: 'Web Development Manager',
      subTitle: 'Egnyte, Mountain View, CA',
      description: 'March 2016 – Till Date',
      resposibilities:'<ul><li>Responsible for the day-to-day development and maintenance of Egnyte’s corporate marketing website using Acquia’s Drupal hosting service, PHP, Twig Template Engine, MySQL, HTML5, CSS3, CSS Preprocessor – SASS, Bootstrap, JavaScript and jQuery.</li><li>Develop, Maintain and Monitor Egnyte’s WordPress Blog using PHP, MySQL, HTML5, CSS3, Bootstrap and jQuery.</li><li>Develop and maintain theming of Egnyte’s Help Desk, University & Community sites.</li><li>Develop responsive/adaptive email experience using HTML, in-line CSS and media queries from design comps for multi browsers and platforms (desktop, tablet and smartphone) that conform to brand guidelines and functional specifications using responsive/mobile design/development techniques and principles.</li><li>Ensuring rendering consistency of email campaigns across multiple email clients and browsers using Litmus.</li><li>Managing version releases using GIT.</li></ul>'
    },
    {
      title: 'Software Applications Developer',
      subTitle: 'Teachscape, Inc., San Francisco, CA',
      description: 'October 2010 – March 2016',
      resposibilities:'<ul><li>Responsible for the development, maintenance and operation of Teachscape’s corporate website using Hippo CMS – a Java based CMS, Servlets, JSP, JSTL, Maven, HTML/HTML5, CSS/CSS3, jQuery, Bootstrap, Stylus and Grunt.</li><li>Responsible for supporting marketing campaigns and product launches by developing emails and landing pages using our content management system, marketing automation system, and customer relationship management system.</li><li>Responsible for implementing and managing web analytics using Google Analytics.</li><li>Responsible for the execution and monitoring of SEO.</li><li>Responsible for developing E-Learning courses using HTML/HTML5, CSS/CSS3, JavaScript and jQuery.</li><li>Responsible for developing/maintaining both the front-end and the back-end of Video Secure Viewer – web application built using Ruby, Sinatra, HAML, CSS/CSS3, JavaScript and jQuery.<li>Developing custom theme in WordPress using PHP, MySQL, HTML5, CSS3, Bootstrap and jQuery.</li></ul>'
    },
    {
      title: 'Web Accessibility Evaluator',
      subTitle: 'SFSU Accessible Technology Initiative, San Francisco, CA',
      description: 'October 2008 – June 2010',
      resposibilities:'<ul><li>Conducted accessibility testing against US ADA Section 508 and WCAG 2.0 standards on 100+ SFSU’s department websites and web applications.</li><li>Ran a user test using adaptive technologies such as JAWS and Dragon Naturally Speaking.</li><li>Performed accessibility testing on popular Web 2.0 sites and evaluated automated accessibility tools.</li><li>Created Accessible Adobe PDF documents.</li></ul>'
    },
    {
      title: 'Web Developer',
      subTitle: 'California Department of Financial Institutions, San Francisco, CA',
      description: 'July 2008 – August 2008 & February 2009 – December 2009',
      resposibilities:'<ul><li>Developed a whole new employee intranet.</li><li>Enhanced and maintained the department’s customer facing website.</li><li>Performed day-to-day content updates for both external and internal websites.</li></ul>'
    },
    {
      title: 'Technical / Administrative Support Assistant',
      subTitle: 'SFSU Information Systems Projects, San Francisco, CA',
      description: 'September 2007 – September 2008',
      resposibilities:'<ul><li>Designed, maintained, and updated the department’s customer facing website.</li><li>Set up Test Bed and provided day-to-day hardware / software technical support.</li><li>Created user support documentation for operational configurations, processes and procedures.</li></ul>'
    },
    {
      title: 'Technology Coordinator',
      subTitle: 'California Campus Compact, Daly City, CA',
      description: 'August 2006 – August 2007',
      resposibilities:'<ul><li>Maintained and updated the organization’s website.</li><li>Maintained and updated the organization’s membership and hardware/software inventory database.</li><li>Managed Listserv communication using Majordomo.</li><li>Managed Web/Email/FTP accounts.</li><li>Performed routine maintenance of software used on Windows and Macintosh systems.</li><li>Responded to software and hardware technical issues of the organization.</li></ul>'
    },
    {
      title: 'Developer and Quality Assurance Engineer',
      subTitle: 'Aptech Software Ltd, Mumbai, India',
      description: 'August 2004 – July 2005',
      resposibilities:'<ul><li>Developed e-learning courseware for financial institutions.</li><li>Executed test cases using standard QA methodologies (Black box testing) on Windows and Blackboard LMS.</li><li>Documented detailed defects/bugs in the defect tracking report and tracked and verified previously reported defects/bugs.</li><li>Proactively participated in maintaining team standards and best practices.</li></ul>'
    },
    {
      title: 'e-Learning Integrator',
      subTitle: 'Lionbridge India, Mumbai, India',
      description: 'June 2003 – August 2004',
      resposibilities:'<ul><li>Performed black box testing of web applications on Windows and Macintosh platform.</li><li>Prepared easy-to-understand test cases.</li> <li>Worked closely with the development, program managers and stake holder’s through the software development cycle to deliver high quality applications.</li><li>Developed e-learning courseware for educational and private institutions.</li></ul>'
    }
  ],
  education: [
    {
      title: 'Master’s in Computer Science',
      subTitle: 'San Francisco State University',
      description: 'August 2010'
    },
    {
      title: 'Bachelor’s in Electronics Engineering',
      subTitle: 'Mumbai University',
      description: 'August 2002'
    },
    {
      title: 'Diploma in Industrial Electronics',
      subTitle: 'MSBTE',
      description: 'August 1999'
    }
  ],
};
